/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
import { AuthHelper } from "@/helpers/AuthHelper";
import Admin from "@/pages/Admin.vue";
import Dashboard from "@/pages/Dashboard.vue";
import Editor from "@/pages/Editor.vue";
import Login from "@/pages/Login.vue";
import ResetPassword from "@/pages/ResetPassword.vue";
import Logger from "@/shared/logger";
import { Privilege } from "@winnove/vue-wlib/enums";
import Cookies from "js-cookie";
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
  createRouter,
  createWebHistory,
} from "vue-router";

export const ROUTE_HOME = "/";
export const ROUTE_LOGIN = "login";
export const ROUTE_LOGOUT = "logout";
export const ROUTE_PROJECTS = "projects";
export const ROUTE_DASHBOARD = "dashboard";
export const ROUTE_PRODUCTIONS = "productions";
export const ROUTE_PRODUCTIONS_DRAW = ROUTE_PRODUCTIONS + "/draw";
export const ROUTE_PRODUCTIONS_I3D = ROUTE_PRODUCTIONS + "/i3d";
export const ROUTE_PRODUCTIONS_TOMAKE = ROUTE_PRODUCTIONS + "/make";
export const ROUTE_PRODUCTIONS_SHIP = ROUTE_PRODUCTIONS + "/shipping";
export const ROUTE_ACCOUNT_MANAGER = "account-manager";
export const ROUTE_ACCOUNT_MANAGER_DELIVERY =
  ROUTE_ACCOUNT_MANAGER + "/delivery";
export const ROUTE_ACCOUNT_MANAGER_VALIDATION =
  ROUTE_ACCOUNT_MANAGER + "/validation";
export const ROUTE_ACCOUNT_MANAGER_BLOCK_PROJECTS =
  ROUTE_ACCOUNT_MANAGER + "/blocked-projects";
export const ROUTE_ACCOUNT_MANAGER_TASKS = ROUTE_ACCOUNT_MANAGER + "/tasks";
export const ROUTE_ACCOUNT = "account";
export const ROUTE_BILLING = "billing";
export const ROUTE_PATIENTS = "patients";
export const ROUTE_EDITOR = "editor";
export const ROUTE_RESET_PASSWORD = "password-reset";
export const ROUTE_ADMIN = "admin";
export const ROUTE_ADMIN_PROJECTS = ROUTE_ADMIN + "/projects";
export const ROUTE_ADMIN_ROBOTS = ROUTE_ADMIN + "/robots";
export const ROUTE_ADMIN_USERS = ROUTE_ADMIN + "/users";
export const ROUTE_ADMIN_CONFIGS = ROUTE_ADMIN + "/configs";
export const ROUTE_ADMIN_BILLING = ROUTE_ADMIN + "/billing";

const routes: RouteRecordRaw[] = [
  // General routes
  { path: ROUTE_HOME, name: ROUTE_HOME, component: Login },

  // Login routes
  {
    path: "/" + ROUTE_LOGIN,
    name: ROUTE_LOGIN,
    component: Login,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/" + ROUTE_RESET_PASSWORD + "/:token?",
    name: ROUTE_RESET_PASSWORD,
    component: ResetPassword,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/" + ROUTE_LOGOUT,
    name: ROUTE_LOGOUT,
    component: Login,
  },

  // User menu routes
  {
    path: "/" + ROUTE_ACCOUNT,
    name: ROUTE_ACCOUNT,
    component: Dashboard,
    props: { p_route: ROUTE_ACCOUNT },
  },
  {
    path: "/" + ROUTE_BILLING,
    name: ROUTE_BILLING,
    component: Dashboard,
    props: { p_route: ROUTE_BILLING },
  },
  {
    path: "/" + ROUTE_PATIENTS,
    name: ROUTE_PATIENTS,
    component: Dashboard,
    props: { p_route: ROUTE_PATIENTS },
  },

  // Main dashboard routes
  {
    path: "/" + ROUTE_PROJECTS + "/:filter?/:id?",
    name: ROUTE_PROJECTS,
    component: Dashboard,
    props: { p_route: ROUTE_PROJECTS },
  },

  //Production Dashboard routes
  {
    path: "/" + ROUTE_PRODUCTIONS,
    name: ROUTE_PRODUCTIONS,
    component: Dashboard,
    props: { p_route: ROUTE_PRODUCTIONS },
  },
  {
    path: "/" + ROUTE_PRODUCTIONS_DRAW,
    name: ROUTE_PRODUCTIONS_DRAW,
    component: Dashboard,
    props: { p_route: ROUTE_PRODUCTIONS_DRAW },
  },
  {
    path: "/" + ROUTE_PRODUCTIONS_I3D,
    name: ROUTE_PRODUCTIONS_I3D,
    component: Dashboard,
    props: { p_route: ROUTE_PRODUCTIONS_I3D },
  },
  {
    path: "/" + ROUTE_PRODUCTIONS_TOMAKE,
    name: ROUTE_PRODUCTIONS_TOMAKE,
    component: Dashboard,
    props: { p_route: ROUTE_PRODUCTIONS_TOMAKE },
  },
  {
    path: "/" + ROUTE_PRODUCTIONS_SHIP,
    name: ROUTE_PRODUCTIONS_SHIP,
    component: Dashboard,
    props: { p_route: ROUTE_PRODUCTIONS_SHIP },
  },

  //Account Manager Dashboard routes
  {
    path: "/" + ROUTE_ACCOUNT_MANAGER_DELIVERY,
    name: ROUTE_ACCOUNT_MANAGER_DELIVERY,
    component: Dashboard,
    props: { p_route: ROUTE_ACCOUNT_MANAGER_DELIVERY },
  },

  {
    path: "/" + ROUTE_ACCOUNT_MANAGER_BLOCK_PROJECTS,
    name: ROUTE_ACCOUNT_MANAGER_BLOCK_PROJECTS,
    component: Dashboard,
    props: { p_route: ROUTE_ACCOUNT_MANAGER_BLOCK_PROJECTS },
  },
  {
    path: "/" + ROUTE_ACCOUNT_MANAGER_VALIDATION,
    name: ROUTE_ACCOUNT_MANAGER_VALIDATION,
    component: Dashboard,
    props: { p_route: ROUTE_ACCOUNT_MANAGER_VALIDATION },
  },
  {
    path: "/" + ROUTE_ACCOUNT_MANAGER_TASKS,
    name: ROUTE_ACCOUNT_MANAGER_TASKS,
    component: Dashboard,
    props: { p_route: ROUTE_ACCOUNT_MANAGER_TASKS },
  },

  {
    path: "/" + ROUTE_PROJECTS + "/:filter?/:id?",
    name: ROUTE_PROJECTS,
    component: Dashboard,
    props: { p_route: ROUTE_PROJECTS },
  },
  {
    path: "/" + ROUTE_PRODUCTIONS,
    name: ROUTE_PRODUCTIONS,
    component: Dashboard,
    props: { p_route: ROUTE_PRODUCTIONS },
  },

  // Admin routes
  {
    path: "/" + ROUTE_ADMIN,
    name: ROUTE_ADMIN,
    redirect: { name: ROUTE_ADMIN_PROJECTS },
  },
  {
    path: "/" + ROUTE_ADMIN_PROJECTS,
    name: ROUTE_ADMIN_PROJECTS,
    component: Admin,
    props: { p_route: ROUTE_ADMIN_PROJECTS },
  },
  {
    path: "/" + ROUTE_ADMIN_USERS,
    name: ROUTE_ADMIN_USERS,
    component: Admin,
    props: { p_route: ROUTE_ADMIN_USERS },
  },
  {
    path: "/" + ROUTE_ADMIN_ROBOTS,
    name: ROUTE_ADMIN_ROBOTS,
    component: Admin,
    props: { p_route: ROUTE_ADMIN_ROBOTS },
  },
  {
    path: "/" + ROUTE_ADMIN_CONFIGS,
    name: ROUTE_ADMIN_CONFIGS,
    component: Admin,
    props: { p_route: ROUTE_ADMIN_CONFIGS },
  },
  {
    path: "/" + ROUTE_ADMIN_BILLING,
    name: ROUTE_ADMIN_BILLING,
    component: Admin,
    props: { p_route: ROUTE_ADMIN_BILLING },
  },

  // Editor routes
  {
    path: "/" + ROUTE_EDITOR + "/:reference?",
    name: ROUTE_EDITOR,
    component: Editor,
    props: { p_route: ROUTE_EDITOR },
  },

  // Catch all

  // will match everything and put it under `$route.params.pathMatch`
  { path: "/:pathMatch(.*)*", redirect: { name: ROUTE_HOME } },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
});

router.beforeEach(
  (
    p_to: RouteLocationNormalized,
    p_from: RouteLocationNormalized,
    p_next: NavigationGuardNext
  ) => {
    const p_toName = p_to.name?.toString() ?? "";

    AuthHelper.refreshToken(); // If page actualized

    // if user is logged in and tries to access login page, redirect to projects
    if (p_to.name == ROUTE_LOGIN && AuthHelper.isLoggedIn()) {
      if (AuthHelper.isProdLoggedIn() && AuthHelper.isAdminLoggedIn()) {
        p_next({ name: ROUTE_PRODUCTIONS });
      } else if (
        AuthHelper.isAccountManagerLoggedIn() &&
        AuthHelper.isAdminLoggedIn()
      ) {
        p_next({ name: ROUTE_ACCOUNT_MANAGER_TASKS });
      } else {
        p_next({ name: ROUTE_PROJECTS });
      }
      // if user is a production user and is logged in and tries to acces to the / page, redirect to prod dashboard
    } else if (p_to.fullPath === "/" && AuthHelper.isLoggedIn()) {
      if (AuthHelper.isProdLoggedIn() && AuthHelper.isAdminLoggedIn())
        p_next({ name: ROUTE_PRODUCTIONS });
      else if (
        AuthHelper.isAccountManagerLoggedIn() &&
        AuthHelper.isAdminLoggedIn()
      )
        p_next({ name: ROUTE_ACCOUNT_MANAGER_TASKS });
      else p_next({ name: ROUTE_PROJECTS });
    }

    // if user is not logged in and tries to access a page that requires authentication, redirect to login
    else if (
      !p_to.meta ||
      (!p_to.meta.allowAnonymous && !AuthHelper.isLoggedIn())
    ) {
      Logger.getInstance().debug("Redirecting to login from " + p_toName);
      if (p_to.name !== ROUTE_LOGOUT) Cookies.set("redirect", p_to.fullPath);
      p_next({ name: ROUTE_LOGIN });
    }

    // if user is not allowed to access admin pages, redirect to projects
    else if (
      AuthHelper.getLoggedUser().privilege !== Privilege.ADMIN &&
      (p_toName.startsWith(ROUTE_ADMIN) ||
        p_toName.startsWith(ROUTE_DASHBOARD) ||
        p_toName.startsWith(ROUTE_PRODUCTIONS) ||
        p_toName.startsWith(ROUTE_ACCOUNT_MANAGER))
    ) {
      p_next({ name: ROUTE_PROJECTS });
    }

    // when user navigates to the editor, store the current route to return to it later
    else if (p_toName === ROUTE_EDITOR && AuthHelper.isLoggedIn()) {
      Cookies.set("redirect-editor", p_from.fullPath);
      p_next();
    }

    // user is allowed to access page
    else {
      p_next();
    }
  }
);

router.afterEach(
  (p_to: RouteLocationNormalized, p_from: RouteLocationNormalized) => {
    // if user is logged out, clear auth token
    if (p_to.name == ROUTE_LOGOUT) {
      AuthHelper.logout();
    }
  }
);

export default router;
