<template>
  <w-container
    key="ContainerDashboard"
    fluid
    class="background_dashboard"
  >
    <AppBar
      ref="appBar"
      :p_privilege="m_privilege"
      :p_route="p_route"
      @new-project="newProjectDialog.show()"
    />
    <div
      v-if="m_loaded"
      class="h-100"
    >
      <Account
        v-if="p_route == ROUTE_ACCOUNT"
        :p_privilege="m_privilege"
        @update="appBar.setDoctorName()"
      />

      <DashboardProjects
        :p_route="p_route"
        v-if="p_route == ROUTE_PROJECTS"
      />

      <DashboardProduction
        :p_route="p_route"
        v-if="
          p_route == ROUTE_PRODUCTIONS ||
          p_route == ROUTE_PRODUCTIONS_DRAW ||
          p_route == ROUTE_PRODUCTIONS_I3D ||
          p_route == ROUTE_PRODUCTIONS_TOMAKE ||
          p_route == ROUTE_PRODUCTIONS_SHIP
        "
      />

      <DashboardAccountManager
        :p_route="p_route"
        v-if="
          p_route == ROUTE_ACCOUNT_MANAGER ||
          p_route == ROUTE_ACCOUNT_MANAGER_DELIVERY ||
          p_route == ROUTE_ACCOUNT_MANAGER_VALIDATION ||
          p_route == ROUTE_ACCOUNT_MANAGER_BLOCK_PROJECTS ||
          p_route == ROUTE_ACCOUNT_MANAGER_TASKS
        "
      />

      <PatientTable v-if="p_route == ROUTE_PATIENTS" />

      <BillingTable v-if="p_route === ROUTE_BILLING" />

      <NewProjectDialog
        ref="newProjectDialog"
        :p_privilege="m_privilege"
        :p_role="m_role"
      />
    </div>
  </w-container>
</template>

<script lang="ts">
import NewProjectDialog from "@/components/dashboard/dialogs/NewProjectDialog.vue";
import AppBar from "@/components/shared/AppBar.vue";
import { AuthHelper } from "@/helpers/AuthHelper";
import WinnoveHelper from "@/helpers/WinnoveHelper";

import {
  ROUTE_ACCOUNT,
  ROUTE_ACCOUNT_MANAGER,
  ROUTE_ACCOUNT_MANAGER_BLOCK_PROJECTS,
  ROUTE_ACCOUNT_MANAGER_DELIVERY,
  ROUTE_ACCOUNT_MANAGER_TASKS,
  ROUTE_ACCOUNT_MANAGER_VALIDATION,
  ROUTE_BILLING,
  ROUTE_PATIENTS,
  ROUTE_PRODUCTIONS,
  ROUTE_PRODUCTIONS_DRAW,
  ROUTE_PRODUCTIONS_I3D,
  ROUTE_PRODUCTIONS_SHIP,
  ROUTE_PRODUCTIONS_TOMAKE,
  ROUTE_PROJECTS,
} from "@/router";
import { Privilege, Role } from "@winnove/vue-wlib/enums";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

export default defineComponent({
  name: "Admin",
  props: {
    p_route: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const m_privilege = ref(Privilege.FREEMIUM);
    const m_role = ref(Role.DEFAULT);
    const m_loaded = ref(false);
    const newProjectDialog = ref<InstanceType<typeof NewProjectDialog> | null>(
      null
    );
    const appBar = ref<InstanceType<typeof AppBar> | null>(null);

    onUnmounted(() => {
      WinnoveHelper.clearAllWithoutSession();
    });

    onMounted(() => {
      (async () => {
        let user = AuthHelper.getLoggedUser();
        m_privilege.value = user.privilege;
        m_role.value = user.role;
        // Clean all without token.
        WinnoveHelper.clearAllWithoutSession();
        let session = WinnoveHelper.getSession();
        session.clear();
        WinnoveHelper.setSession(session);

        // Fetch from server.
        await WinnoveHelper.fetchConfig();
        m_loaded.value = true;
      })();
    });

    return {
      ROUTE_PROJECTS,
      ROUTE_ACCOUNT,
      ROUTE_PATIENTS,
      ROUTE_BILLING,
      ROUTE_PRODUCTIONS,
      ROUTE_PRODUCTIONS_DRAW,
      ROUTE_PRODUCTIONS_I3D,
      ROUTE_PRODUCTIONS_TOMAKE,
      ROUTE_PRODUCTIONS_SHIP,
      ROUTE_ACCOUNT_MANAGER,
      ROUTE_ACCOUNT_MANAGER_DELIVERY,
      ROUTE_ACCOUNT_MANAGER_TASKS,
      ROUTE_ACCOUNT_MANAGER_VALIDATION,
      ROUTE_ACCOUNT_MANAGER_BLOCK_PROJECTS,
      Privilege,
      m_privilege,
      m_role,
      m_loaded,
      newProjectDialog,
      appBar,
    };
  },
});
</script>

<style lang="scss" scoped>
.background_dashboard {
  height: 100%;
  padding: 0;
}

#help-button {
  position: fixed;
  bottom: 0;
  margin: 16px;
  cursor: pointer;

  * {
    transition: 0.2s;
  }

  :hover {
    transform: scale(1.05);
  }
}
</style>
