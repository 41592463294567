import Project from "@/models/Project";
import Logger from "@/shared/logger";
import { Arcade, PointType } from "@winnove/vue-wlib/enums";
import { Mesh, Scene as Scene3D, Vector3 } from "three";
import { CtrlZAction } from "./SceneCommon";
import SceneControls from "./SceneControls";
import SceneJawArchWireMesh from "./SceneJawArchWireMesh";
import SceneJawArchWirePlane from "./SceneJawArchWirePlane";
export default class SceneJawArchWire {
  public wireMesh: SceneJawArchWireMesh;
  public plane: SceneJawArchWirePlane;
  private _project: Project | null;
  private _arcade: Arcade;
  private _loadedPointsType: PointType = -1;

  constructor(
    scene: Scene3D,
    p_arcade: Arcade,
    project: Project | null,
    controls: SceneControls
  ) {
    this._arcade = p_arcade;
    this.wireMesh = new SceneJawArchWireMesh(
      scene,
      p_arcade,
      project,
      controls
    );
    this.plane = new SceneJawArchWirePlane(scene, p_arcade, project, controls);
    this._project = project;
  }

  public updateProject(p_project: Project) {
    this._project = p_project;
    this.wireMesh.updateProject(p_project);
    this.plane.updateProject(p_project);
  }

  public disposeAll(): void {
    this.wireMesh.disposeAll();
    this.plane.disposeAll();
  }

  public disposeFinal(): void {
    this.wireMesh.disposeAll();
    this.plane.disposeAll();
    this.wireMesh = null!;
    this.plane = null!;
  }

  public rotate(p_axis: Vector3, p_angle: number): void {
    this.wireMesh.rotate(p_axis, p_angle);
    this.plane.rotate(p_axis, p_angle);
  }

  public setWireMeshVisible(p_visible: boolean): void {
    this.wireMesh.setMeshVisible(p_visible);
  }

  public setPlaneMeshVisible(p_visible: boolean): void {
    this.plane.setMeshVisible(p_visible);
  }

  public isVisible(): boolean {
    return this.wireMesh.isVisible();
  }

  public setWirePointsVisible(p_visible: boolean): void {
    this.wireMesh.setPointsVisible(p_visible);
  }

  public setPlanePointsVisible(p_visible: boolean): void {
    this.plane.setPointsVisible(p_visible);
  }

  public toggleKeyMode(): void {
    this.wireMesh.toggleKeyMode();
  }

  public loadPoints(p_type: PointType) {
    // Check if there is a prescription
    if (!this._project?.getPrescription(this._arcade)) {
      Logger.getInstance().warning(
        "Impossible d'ajouter un point sur cette arcade car il n'y a pas de prescription"
      );
      return;
    }
    // Add point to the right mesh
    switch (p_type) {
      case PointType.PLANE:
        this.plane.loadPoints(
          this._project.getPrescription(this._arcade)!.planePoints
        );
        break;
      case PointType.BASE:
        this.wireMesh.loadPoints(
          this._project.getPrescription(this._arcade)!.basePoints,
          p_type
        );
        break;
      case PointType.WIRE:
        this.wireMesh.loadPoints(
          this._project.getPrescription(this._arcade)!.wirePoints,
          p_type
        );
        break;
    }
    if (p_type !== PointType.PLANE) this._loadedPointsType = p_type;
  }

  public addPoint(
    p_point: Vector3,
    p_normal: Vector3,
    p_type: PointType,
    p_id: number,
    p_scanMesh: Mesh,
    p_camPos: Vector3
  ): CtrlZAction | undefined {
    // Check if there is a prescription
    if (!this._project?.getPrescription(this._arcade)) {
      Logger.getInstance().warning(
        "Impossible d'ajouter un point sur cette arcade car il n'y a pas de prescription"
      );
      return;
    }
    // Add point to the right mesh
    if (p_type === PointType.PLANE) {
      return this.plane.addPoint(p_point, p_normal, p_type, p_id);
    } else {
      if (p_type === PointType.BASE && this.plane.isValid()) {
        // If there is a plane, snap the base point to it
        const projectedRaycast = this.plane.projectRaycast(
          p_camPos,
          p_point,
          p_scanMesh
        );
        if (projectedRaycast) p_point.copy(projectedRaycast);
        else {
          Logger.getInstance().warning(
            "Impossible de projeter le point sur le plan"
          );
          return;
        }
        // project the normal on the plane
        p_normal.projectOnPlane(this.plane.getPlane()!.normal);
      }
      this._loadedPointsType = p_type;
      return this.wireMesh.addPoint(
        p_point,
        p_normal.normalize(),
        p_type,
        p_id
      );
    }
  }

  public getPoints(p_type: PointType): string {
    if (this._loadedPointsType !== p_type) {
      // only for base and wire points
      Logger.getInstance().debug(
        "Impossible de récupérer les points de type " +
          PointType[p_type] +
          " car ils n'ont pas été chargés."
      );
      switch (p_type) {
        case PointType.WIRE:
          return this._project!.getPrescription(this._arcade)!.wirePoints;
        case PointType.BASE:
          return this._project!.getPrescription(this._arcade)!.basePoints;
      }
    }
    switch (p_type) {
      case PointType.WIRE:
        return this.wireMesh.getWirePoints();
      case PointType.BASE:
        return this.wireMesh.getPoints();
      case PointType.PLANE:
        return this.plane.getPoints();
    }
    return "";
  }

  public getPointsNb(p_type: PointType): number {
    if (this._loadedPointsType !== p_type) {
      // only for base and wire points
      Logger.getInstance().debug(
        "Impossible de récupérer le nombre de points de type " +
          PointType[p_type] +
          " car ils n'ont pas été chargés."
      );
      switch (p_type) {
        case PointType.WIRE:
          return JSON.parse(
            this._project!.getPrescription(this._arcade)!.wirePoints
          ).length;
        case PointType.BASE:
          return JSON.parse(
            this._project!.getPrescription(this._arcade)!.basePoints
          ).length;
      }
    }
    switch (p_type) {
      case PointType.WIRE:
      case PointType.BASE:
        return this.wireMesh.getPointsNb();
      case PointType.PLANE:
        return this.plane.getPointsNb();
    }
    return 0;
  }

  public updateWireMesh(p_visible: boolean): void {
    this.wireMesh.updateWireMesh(p_visible);
  }

  public updateZonePoints(p_visible: boolean, p_scan: Mesh): void {
    this.wireMesh.updateZonePoints(p_visible, p_scan);
  }

  public updatePenetration(p_visible: boolean, p_scan: Mesh): void {
    this.wireMesh.updatePenetration(p_visible, p_scan);
    this.wireMesh.updateFitVisualisation(p_visible, p_scan);
  }

  public selectPoint(p_id: number) {
    this.wireMesh.selectPoint(p_id);
  }

  public snapOnPlane(p_scanMesh: Mesh): boolean {
    return this.wireMesh.points.every((p_point) => {
      const newPos = this.plane.projectRaycast(
        new Vector3(0, 0, 0),
        p_point.p,
        p_scanMesh
      );
      if (newPos) p_point.p.copy(newPos);
      else return false;
      return true;
    });
  }

  public changePosition(p_id: number, p_position: Vector3) {
    this.wireMesh.points[p_id].m.position.copy(p_position);
  }

  public getPointPosition(p_id: number): Vector3 {
    return this.wireMesh.getPointPosition(p_id);
  }

  public getPointMeshPosition(p_id: number): Vector3 {
    return this.wireMesh.getPointMeshPosition(p_id);
  }
}
