import Logger from "@/shared/logger";
import { Matrix4, Mesh, Vector3 } from "three";

export class MeshHelper {
  static prepareJawMesh(
    p_mesh: Mesh,
    p_date: string | null,
    p_matrixStr: string
  ): void {
    let old = false;
    if (p_date) old = new Date(p_date) < new Date("January 16, 2023 18:25:00");

    if (!p_matrixStr || old) {
      p_mesh.position.set(0, 0, 0);
      p_mesh.rotation.set(0, Math.PI, 0);
      p_mesh.geometry.computeBoundingBox();
      const geometryCopy = p_mesh.geometry.clone();
      geometryCopy.center();
      const bbox = geometryCopy.boundingBox;
      if (
        bbox != undefined &&
        bbox.max.y - bbox.min.y > bbox.max.z - bbox.min.z
      ) {
        p_mesh.rotateOnWorldAxis(new Vector3(1, 0, 0), Math.PI / 2);
      }
      if (old && p_matrixStr)
        p_mesh.applyMatrix4(new Matrix4().fromArray(JSON.parse(p_matrixStr)));
      p_mesh.updateMatrixWorld(true);
    } else {
      try {
        const matrix: Matrix4 = new Matrix4().fromArray(
          JSON.parse(p_matrixStr)
        );
        p_mesh.applyMatrix4(matrix);
        p_mesh.updateMatrixWorld(true);
      } catch (p_e) {
        Logger.getInstance().error(String(p_e));
      }
    }
  }

  static dispose(p_mesh?: Mesh): void {
    if (p_mesh && p_mesh instanceof Mesh) {
      p_mesh.geometry.dispose();
      if (p_mesh.geometry) {
        p_mesh.geometry.dispose();
      }
      if (p_mesh.material) {
        if (Array.isArray(p_mesh.material)) {
          p_mesh.material.forEach((p_material) => {
            p_material.dispose();
          });
        } else {
          p_mesh.material.dispose();
        }
      }
    }
  }

  static degreeToRad(p_degree: number): number {
    return (p_degree * Math.PI) / 180;
  }
}
