import { Constants } from "@/Constants";
import { MeshHelper } from "@/helpers/MeshHelper";
import Project from "@/models/Project";
import {
  Arcade,
  Dimension,
  EditorState,
  Material,
  PointType,
} from "@winnove/vue-wlib/enums";
import { Box3, Group, Matrix4, Mesh, Scene as Scene3D, Vector3 } from "three";
import { CtrlZAction } from "./SceneCommon";
import SceneControls from "./SceneControls";
import SceneJawArch from "./SceneJawArch";
import SceneJawArchWireMesh from "./SceneJawArchWireMesh";

export default class SceneJaw {
  public maxi: SceneJawArch;
  public mandi: SceneJawArch;
  public meshesGroup: Group;
  public wireGimbalMeshes: Mesh[] = new Array(
    Constants.CONTROLS_GIMBAL_MESHES_AMOUNT
  );
  public ctrlZHistory: CtrlZAction[] = [];
  public ctrlYHistory: CtrlZAction[] = [];
  public jsonWires: Mesh[] = [];
  private _project: Project;
  private _controls: SceneControls;
  private _scene: Scene3D;

  constructor(scene: Scene3D, project: Project, controls: SceneControls) {
    this.maxi = new SceneJawArch(Arcade.MAXILLA, scene, project, controls);
    this.mandi = new SceneJawArch(Arcade.MANDIBLE, scene, project, controls);
    this.meshesGroup = new Group();
    scene.add(this.meshesGroup);
    for (let i = 0; i < Constants.CONTROLS_GIMBAL_MESHES_AMOUNT; i++) {
      scene.add((this.wireGimbalMeshes[i] = new Mesh()));
      this.wireGimbalMeshes[i].name =
        i < Constants.CONTROLS_GIMBAL_MESHES_AMOUNT
          ? "vertical"
          : "transversal" + i;
    }
    this._scene = scene;
    this._project = project;
    this._controls = controls;
  }
  public invertJawMandiMaxi(): void {
    const temp = this.mandi;
    this.mandi = this.maxi;
    this.maxi = temp;

    if (this.maxi.scan.mesh) {
      this.maxi.scan.mesh.name = Constants.MESH_MAXILLA_NAME;
    }
    if (this.mandi.scan.mesh) {
      this.mandi.scan.mesh.name = Constants.MESH_MANDIBLE_NAME;
    }
  }

  public isJawInverted(): boolean {
    const mandibleMesh = this.mandi.scan.mesh;
    const maxillaMesh = this.maxi.scan.mesh;

    if (!mandibleMesh || !maxillaMesh) {
      return false;
    }

    const mandibleBox = new Box3().setFromObject(mandibleMesh);
    const maxillaBox = new Box3().setFromObject(maxillaMesh);

    const mandibleCenter = new Vector3();
    const maxillaCenter = new Vector3();

    mandibleBox.getCenter(mandibleCenter);
    maxillaBox.getCenter(maxillaCenter);

    return mandibleCenter.y >= maxillaCenter.y;
  }

  public updateProject(p_project: Project) {
    this._project = p_project;
    this.maxi.updateProject(p_project);
    this.mandi.updateProject(p_project);
  }

  public disposeAll(): void {
    this.maxi.disposeAll();
    this.mandi.disposeAll();
    this.wireGimbalMeshes.forEach((mesh) => {
      MeshHelper.dispose(mesh);
    });
  }

  public disposeFinal(): void {
    this.maxi.disposeFinal();
    this.mandi.disposeFinal();
    this.wireGimbalMeshes.forEach((mesh) => {
      MeshHelper.dispose(mesh);
      this._scene.remove(mesh);
    });
    // Dereference to allow garbage collection.
    this.wireGimbalMeshes = [];
    this._scene = null!;
    this._controls = null!;
    this.maxi = null!;
    this.mandi = null!;
  }

  public rotate(p_axis: Vector3, p_angle: number): void {
    this.maxi.rotate(p_axis, p_angle);
    this.mandi.rotate(p_axis, p_angle);
  }

  public loadPoints(p_type: PointType) {
    this.maxi.loadPoints(p_type);
    this.mandi.loadPoints(p_type);
  }

  public addPoint(
    p_arcade: Arcade,
    p_point: Vector3,
    p_normal: Vector3,
    p_type: PointType,
    p_id: number,
    p_camPos: Vector3 = new Vector3()
  ): CtrlZAction | undefined {
    if (p_arcade === Arcade.MAXILLA) {
      return this.maxi.addPoint(p_point, p_normal, p_type, p_id, p_camPos);
    } else {
      return this.mandi.addPoint(p_point, p_normal, p_type, p_id, p_camPos);
    }
  }

  public exportWireMesh(p_arcade: Arcade) {
    if (p_arcade === Arcade.MAXILLA) {
      this.maxi.exportWireMesh();
    } else {
      this.mandi.exportWireMesh();
    }
  }

  public exportWireMeshAndJawTogether(p_arcade: Arcade) {
    if (p_arcade === Arcade.MAXILLA) {
      this.maxi.exportWireMeshAndJawTogether();
    } else {
      this.mandi.exportWireMeshAndJawTogether();
    }
  }

  public getScanMatrix(p_arcade: Arcade): Matrix4 | null {
    if (p_arcade === Arcade.MAXILLA) {
      return this.maxi.getScanMatrix();
    } else {
      return this.mandi.getScanMatrix();
    }
  }

  public getPoints(p_arcade: Arcade, p_type: PointType): string {
    if (p_arcade === Arcade.MAXILLA) {
      return this.maxi.getPoints(p_type);
    } else {
      return this.mandi.getPoints(p_type);
    }
  }

  public getPointsNb(p_arcade: Arcade, p_type: PointType): number {
    if (p_arcade === Arcade.MAXILLA) {
      return this.maxi.getPointsNb(p_type);
    } else {
      return this.mandi.getPointsNb(p_type);
    }
  }

  public updateWireMesh(p_arcade: Arcade) {
    if (p_arcade === Arcade.MAXILLA) {
      this.maxi.updateWireMesh();
    } else {
      this.mandi.updateWireMesh();
    }
  }

  public updateWireMeshes() {
    this.maxi.updateWireMesh();
    this.mandi.updateWireMesh();
  }

  public setPlanePointsVisible(p_visible: boolean) {
    this.maxi.setPlanePointsVisible(p_visible);
    this.mandi.setPlanePointsVisible(p_visible);
  }

  public setPlaneVisible(p_visible: boolean) {
    this.maxi.setPlaneVisible(p_visible);
    this.mandi.setPlaneVisible(p_visible);
  }

  public setWirePointsVisible(p_visible: boolean) {
    this.maxi.setWirePointsVisible(p_visible);
    this.mandi.setWirePointsVisible(p_visible);
  }

  public updateScene(p_editorState: EditorState, p_selectedArcade: Arcade) {
    this.maxi.updateScene(p_editorState, p_selectedArcade);
    this.mandi.updateScene(p_editorState, p_selectedArcade);
  }

  public snapOnPlane(): boolean {
    let ret: boolean = true;
    ret = ret && this.maxi.snapOnPlane();
    ret = ret && this.mandi.snapOnPlane();
    return ret;
  }

  public loadWires(p_dataWires: any[]) {
    const wireMesh = new SceneJawArchWireMesh(
      this._scene,
      Arcade.NONE,
      this._project,
      this._controls
    );

    p_dataWires.forEach((p_dataWire) => {
      const dimension: Dimension = p_dataWire.dimension;
      const isSquared: boolean = p_dataWire.isSquared;
      const historic: boolean = p_dataWire.historic;
      const material: Material = p_dataWire.material;
      const points: Vector3[] = p_dataWire.points.map(
        (point: any) => new Vector3(point.x, point.y, point.z)
      );
      const mesh = wireMesh.getWireMesh(
        points,
        historic,
        dimension,
        isSquared,
        material
      );
      if (p_dataWire.history) {
        this.jsonWires.forEach((jsonWire) => {
          this._scene.remove(jsonWire);
        });
        this.jsonWires = [];
      }
      this.jsonWires.push(mesh);
      this._scene.add(mesh);
    });
  }

  public unloadWires() {
    this.jsonWires.forEach((jsonWire) => {
      this._scene.remove(jsonWire);
    });
    this.jsonWires = [];
  }

  public toggleWire(p_index: number, p_arcade: Arcade): boolean | undefined {
    if (this.jsonWires[p_index]) {
      this.jsonWires[p_index].visible = !this.jsonWires[p_index].visible;
      return this.jsonWires[p_index].visible;
    } else if (p_arcade === Arcade.MAXILLA)
      return this.maxi.wire.wireMesh.toggleMeshVisible();
    else return this.mandi.wire.wireMesh.toggleMeshVisible();
  }

  public applyMatrix4(p_matrix: Matrix4) {
    this.maxi.applyMatrix4(p_matrix);
    this.mandi.applyMatrix4(p_matrix);
  }

  public resetScanMatrix() {
    this.maxi.resetScanMatrix();
    this.mandi.resetScanMatrix();
  }
}
